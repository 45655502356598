<template>
  <v-row justify="center">
    <v-col sm="12" md="6" lg="6">
      <v-dialog v-model="exportDialog" max-width="500px">
        <v-card class="add-menu containerbox">
          <v-card-title class="text-subtitle-1 mx-11">The export threshold limit is reached, to download <br />exact records please refine the
            search criteria.
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <template>
              <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click="exportData">Cancel
              </v-btn>
            </template>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'exportLimit',
  props: {
    exportDialog: Boolean,
    totalRecords: Number,
  },

  data() {
    return {

    }
  },
  methods: {

    exportData() {
      this.exportDialog = false;
      this.$emit('exportData');
    }


  },
}
</script>