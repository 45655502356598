import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import lotDetailService from "../js/receiving-service.js";
import html2pdf from "html2pdf.js";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
import commonService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: "",
      maxEndDate: new Date(new Date().getTime() + 86400000),
      subPageUrl: "",
      sDate:"",
      eDate:"",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchText: "",
      selectedProject: "",
      projectList: [],
      selectedCarrier: "",
      export50kData: false,
      exportDialog: false,
      carrierList: [],
      selectedUser: "",
      userList: [],
      totalRecords: 0,
      lotList: [],
      exportDisable: true,
      startDate: "",
      endDate: "",
      endDateDisabled: true,
      carrierDisabled: true,
      userDisabled: true,
      searchButton: true,
      excelName: "",
      searchResults: [],
      pageCount: 0,
      page: 1,
      pdfName: "",
      printDisabled: true,
      totalLotDetails: [],
      tblTag: "",
      trTag: "",
      thTag: "",
      tdTag: "",
      json_fields: {
        Project: "project",
        "Lot #": "lotnumber",
        Carrier: "carrier",
        "Tracking Number": "trackingnumber",
        "Staging Location": "stagingLocation",
        Quantity: "qty",
        Reference: "reference",
        "Log Date/Time": "logged_date",
        "Logged By": "logged_by",
        "Lot Closed": "islotclose",
      },
      lotDetailHeaders: [
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Lot #", value: "lotnumber", class: "primary customwhite--text" },
        { text: "Carrier", value: "carrier", class: "primary customwhite--text" },
        { text: "Tracking Number", value: "trackingnumber", class: "primary customwhite--text" },
        { text: "Staging Location", value: "stagingLocation", class: "primary customwhite--text" },
        { text: "Quantity", value: "qty", class: "primary customwhite--text" },
        { text: "Reference", value: "reference", class: "primary customwhite--text" },
        { text: "Log Date/Time", value: "logged_date", class: "primary customwhite--text" },
        { text: "Logged By", value: "logged_by", class: "primary customwhite--text" },
        { text: "Lot Closed", value: "islotclose", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectDetails();
  },
  computed: {
    computedStartDateFormatted() {
      return this.startDate ? moment(this.startDate).format("MM/DD/YYYY") : "";
    },
    computedEndDateFormatted() {
      return this.endDate ? moment(this.endDate).format("MM/DD/YYYY") : "";
    },
  },
  mounted() {},
  methods: {
    //Export pdf
    async printDetails() {
      let searchObject = {
        UserId: parseInt(this.userId),
        SearchStr: this.searchText,
        PageNumber: this.page,
        SearchType: 2,
        AdvJson: JSON.stringify({
          Proj_Key: parseInt(this.selectedProject),
          Carrier: this.selectedCarrier,
          User: this.selectedUser,
          StartDate: this.computedStartDateFormatted,
          EndDate: this.computedEndDateFormatted,
        }),
      };
      const response = await this.axios.post("cr/search_lot_detail", searchObject);
      let responseData = JSON.parse(response.data.body.message).Resultset;
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(responseData);
      this.pdfName = "Lot_Detail_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          try {
            if (typeof cellValue === "boolean") {
              td.appendChild(document.createTextNode(cellValue || ""));
              tr.appendChild(td);
            } else {
              new Date(cellValue);
              td.appendChild(document.createTextNode(Utility.convertESTToLocal(cellValue) || ""));
              tr.appendChild(td);
            }
          } catch (e) {
            td.appendChild(document.createTextNode(cellValue || ""));
            tr.appendChild(td);
          }
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "project") key = "Project";
              if (key == "carrier") key = "Carrier";
              if (key == "lotnumber") key = "Lot #";
              if (key == "trackingnumber") key = "Tracking Number";
              if (key == "qty") key = "Quantity";
              if (key == "reference") key = "Reference";
              if (key == "createddate") key = "Created Date";
              if (key == "logged_date") key = "Logged Date";
              if (key == "logged_by") key = "Logged By";
              if (key == "islotclose") key = "Lot Closed";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Pagination Click event
    pageNumber(page) {
      this.page = page;
      this.onSearchClick(this.page);
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    // File name for Downloading excel
    fileName() {
      if (this.totalRecords <= 15000 )
      this.excelName =
        "Lot_Detail_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Reset Function
    resetFunction() {
      this.searchText = "";
      this.selectedProject = "";
      this.projectList = [];
      this.selectedCarrier = "";
      this.carrierList = [];
      this.selectedUser = "";
      this.userList = [];
      this.totalRecords = 0;
      this.lotList = [];
      this.exportDisable = true;
      this.startDate = "";
      this.endDate = "";
      this.endDateDisabled = true;
      this.carrierDisabled = true;
      this.userDisabled = true;
      this.searchButton = true;
      this.excelName = "";
      this.pageCount = 0;
      this.page = 1;
      this.printDisabled = true;
      this.getProjectDetails();
    },
    //Getting project Details
    async getProjectDetails() {
      let projData = await commonService.getProjectAll("get", this.userId);
      if (projData !== null && projData !== undefined) {
        this.projectList = projData.ProjectInfo;
      }
    },
    //Setting End Date based on Start Date
    setEndDate() {
      if (!this.endDate) {
        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.startDate.getTime() + 86400000);
        this.endDate = this.endDate.toISOString().substr(0, 10);
        this.startDate = this.startDate.toISOString().substr(0, 10);
      }

      let minEndDate = new Date(this.startDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 86400000).toISOString().substr(0, 10);
      this.userChange();
      this.endDateDisabled = false;
    },
    //Carrier List
    async projectChange() {
      let carrierData = await commonService.getCarrierList("get", this.userId);
      if (carrierData !== null && carrierData !== undefined) {
        this.carrierList = carrierData;
        this.carrierDisabled = false;
        if (this.selectedCarrier !== null && this.selectedCarrier !== "") {
          this.carrierChange();
        }
        this.userChange();
      }
    },
    //Carrier Change
    async carrierChange() {
      let array = JSON.stringify(this.selectedCarrier);
      let carrier = array.slice(1, -1);
      let userData = await lotDetailService.getUserList("get", this.userId, this.selectedProject, carrier);
      if (userData !== null && userData !== undefined) {
        if (userData.message == "NA") {
          this.userList = [];
          this.userDisabled = true;
        } else {
          this.selectedUser = "";
          this.userList = userData;
          this.userDisabled = false;
          this.userChange();
        }
      }
    },
    //User Change
    userChange() {
      if (this.selectedProject !== "") {
        if (this.selectedCarrier.length !== 0) {
          if (this.selectedUser.length !== 0) {
            if (this.startDate !== "" && this.endDate !== "" && this.startDate !== null && this.endDate !== null) {
              this.searchButton = false;
              this.exportDisable = true;
              this.printDisabled = true;
              this.lotList = [];
              this.totalRecords = 0;
              this.pageCount = 0;
              this.page = 1;
            } else {
              this.searchButton = true;
            }
          } else {
            this.searchButton = true;
          }
        } else {
          this.searchButton = true;
        }
      } else {
        this.searchButton = true;
      }
    },
    //Lot Search button click event
    async onSearchClick() {
      this.exportDialog = false;
      !this.startDate?this.sDate=new Date((new Date().getTime() - 86400000)).toISOString().substr(0, 10):
      this.sDate=Utility.convertLocalToUTC(this.startDate)
      this.eDate=Utility.convertLocalToUTC(this.endDate)
      let searchObj = {
        UserId: parseInt(this.userId),
        SearchStr: this.searchText,
        PageNumber: this.page,
        SearchType: 1,
        AdvJson: JSON.stringify({
          Proj_Key: parseInt(this.selectedProject),
          Carrier: this.selectedCarrier,
          User: this.selectedUser,
          StartDate: this.sDate,
          EndDate:this.eDate,
        }),
      };
      this.searchResults = [];
      this.searchResults = await lotDetailService.searchLot("post", searchObj);
      if (this.searchResults !== null && this.searchResults !== undefined) {
        let data = this.searchResults.Resultset;
        if (data !== "") {
          this.lotList = [];
          this.lotList = data.map((obj) => {
            return {
              ...obj,
              logged_date: Utility.convertESTToLocal(obj.logged_date),
            };
          });
          if (this.searchResults.TotalRecords > 0) {
            this.exportDisable = false;
            this.totalRecords = this.searchResults.TotalRecords;
            this.pageCount = this.searchResults.TotalPages;
            this.printDisabled = false;
          }
        } else {
          var Alert = {
            type: "error",
            isShow: true,
            message: "No data is available",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.lotList = [];
          this.totalRecords = 0;
          this.printDisabled = true;
          this.exportDisable = true;
        }
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },

    // Export API call
    // Export API
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        let searchObject = {
          UserId: parseInt(this.userId),
          SearchStr: this.searchText,
          PageNumber: this.page,
          SearchType: 2,
          AdvJson: JSON.stringify({
            Proj_Key: parseInt(this.selectedProject),
            Carrier: this.selectedCarrier,
            User: this.selectedUser,
            StartDate: this.computedStartDateFormatted,
            EndDate: this.computedEndDateFormatted,
          }),
        };
        const date = new Date();
        this.excelName =
          "LotDetailsReport" +
          (1 + date.getMonth()).toString().padStart(2, "0") +
          "_" + // 9
          date.getDate().toString().padStart(2, "0") +
          "_" + // 30
          date.getFullYear() +
          ".xls";
        const response = await this.axios.post("cr/search_lot_detail", searchObject);
        let responseData = JSON.parse(response.data.body.message).Resultset;
        this.export50kData = false;
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //On Search Cancel button click event
    onSearchCancel() {
      this.resetFunction();
    },
  },
  components: {
    breadcrumbComp,
    html2pdf,
    exportLimit,
  },
};
